<template>
    <div class="" style="margin-top: 50px;">
        <div class="vld-parent" style="margin-left: auto; margin-right: auto;">
            <loading
                    :active.sync="open_loader"
                    :can-cancel="false"
                    :is-full-page="true"
                    :color="'red'"
            >
            </loading>
        </div>
        <div v-if="app_owner==='HKIE'" style="display: flex; justify-content: center; padding-bottom: 15px">
            <button style="border-radius: 10px;background-color: #00a6db;border:none;" >
                <router-link to="/"  class="nav-link btn" style="color: white; margin-left: 20px; margin-right: 20px"> <strong>NAZAD</strong> </router-link>
            </button>
        </div>
        <div v-else style="display: flex; justify-content: center; padding-bottom: 15px">
            <button style="border-radius: 10px;background-color: red;border:none;" >
                <router-link to="/"  class="nav-link btn" style="color: white; margin-left: 20px; margin-right: 20px"> <strong>NAZAD</strong> </router-link>
            </button>

        </div>

            <div class="box cardBox container" v-if="prijave.length === 0">
                Trenutno nema aktivnih Webinara!
            </div>

            <div class="row" style="background-color: rgba(34, 31, 32, 0.83); min-height: 100%;" v-if="prijave.length > 0">
                <!-- loader -->
                <div v-for="(prijava, index ) in prijave" :key="index" class="col-md-12 text-white text-xl-left">
                    <div v-for="(webinar, index2) in prijava.skupovi.webinars" :key="index2">
                        <a @click.prevent="play(prijave[index], webinar )">
                            <div class="row border-bottom border-bottom-0">
                                <div class="col-md-3">
                                    <br>
                                    <div class="align-middle"  style="text-align: center;">
                                        <img class="" src="@/img/buttons/play.png" style="height: 40px; width: 40px">
                                        <br><strong>POKRENI</strong>
                                    </div>
                                </div>
                                <br>
                                <div class="col-md-9">
                                    <h2>{{ prijava.skupovi.naziv }}</h2>
                                    <h3 v-if="webinar.skupovi_teme && webinar.skupovi_teme.naziv != prijava.skupovi.naziv">{{ webinar.skupovi_teme.naziv }}</h3>
                                    <h3>{{ webinar.datum_od }}</h3>
                                    <p v-if="webinar.opis != prijava.skupovi.naziv" v-html="webinar.opis"></p>

                                </div>
                            </div>
                        </a>
                    </div>

                    <br>
                    <br>
                </div>
            </div>

    </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import toastr from 'toastr';

export default {
    name: "Webinai",
    components: {
        Loading,
    },
    data(){
        return {
            open_loader: false,
            app_owner: process.env.VUE_APP_APP_OWNER,
            prijave: []
        }
    },
    methods:{
        init(){
            let vm=this
            vm.open_loader=true
            axios.get( process.env.VUE_APP_API_URL + 'prijava', {
                params: {
                    options: {
                        korisnik_webinari: true
                    }
                }
            } )
                    .then( function ( response ) {
                        vm.prijave = response.data.data
                        vm.open_loader = false;
                    } )
                    .catch( function ( error ) {
                            vm.open_loader = false;
                            if(error instanceof Error) {
                                console.log( 'Error: ' . error);
                            } else {
                                console.log( 'Unexpected response: ' . error);
                            }
                        } )
        },

        play( prijava, webinar ){
            if( webinar.url ){
                axios( {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + 'webinar-sudionik',
                    data: {
                        webinar_id: webinar.id,
                        prijava_id: prijava.id
                    }
                } )
                        .then( function( response ) {
                            if( response.data.success ){
                                window.open(webinar.url, "_blank");
                            } else {
                                toastr.error( 'Došlo je do greške' )
                            }
                        } )
                        .catch( function ( response ) {
                            console.log( response )
                        } )
            } else {
                toastr.error( 'Pokušajte kasnije' )
            }
        }

    },
    mounted() {
        this.init()
        this.interval = setInterval(() => this.init(), 500000);
    }
}
</script>

<style scoped>

.cardBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #1a2d41;
    font-size: xxx-large;

}

@media (max-width: 576px) {
    .cardBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #1a2d41;
        font-size: xx-large;

    }
}


.box {
    max-width: 920px;
    border: 1px solid #DED8D8;
    padding: 10px;

    background-color: #DED8D8;
    border-radius: 10px;
    display: flex;
    justify-content: center;
}
</style>
